import Checker from 'components/Checker';
import config from 'config';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'reducer';
import { errorAlert } from 'reducer/slices/alertSlice';
import { bottomDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';
import utils from 'tools/utils';

const tabTitle = {
  send: 'ارسال سریع',
  receive: 'دریافت سریع',
};

const companyTitle: any = {
  one: 'وان ایکس بت',
  pull: 'پول بت',
  your: 'سایت شما',
};

const Form = () => {
  const allData = useAppSelector((state) => state.data);
  const useUser = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const [receiver, setReceiver] = useState('');
  const [code, setCode] = useState('');
  const [code1, setCode1] = useState('');
  const [wallet, setWallet] = useState('');
  const [email, setEmail] = useState(useUser.profile.email!);
  const [coin, setCoin] = useState('UUSD');
  const [tab, setTab] = useState(tabTitle.send);
  const [company, setCompany] = useState(companyTitle.one);

  const current = allData.coin.find((e: any) => e.symbol === coin);

  let percent = 0;
  if (company === companyTitle.one) {
    if (coin === 'PM' || coin === 'cVoucher') {
      percent = 1.01;
    } else {
      percent = 1.03;
    }
  } else if (company === companyTitle.pull) {
    if (coin === 'PM' || coin === 'cVoucher') {
      percent = 0;
    } else {
      percent = 1.03;
    }
  }

  const send = async () => {
    const confirmAction = window.confirm(`You want to send to ${receiver || '-'}. Are you sure?`);
    if (confirmAction) {
      confirmSend();
    }
  };

  const receive = async () => {
    const confirmAction = window.confirm(`You want to receive to ${receiver || '-'}. Are you sure?`);
    if (confirmAction) {
      confirmReceive();
    }
  };

  const confirmSend = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/express/send`, true).post({
      email: email,
      code: code + (code1 && '-' + code1),
      receiver: receiver,
      coin: coin,
      company: Object.keys(companyTitle).find((key) => companyTitle[key] === company),
    });

    if (result.code === 200) {
      // dispatch(successAlert('Success Send'));
      window.history.pushState({}, '', '?check=' + result.data.id);
      dispatch(bottomDrawer(<Checker data={result.data} />));
      setCode('');
      setCode1('');
      setEmail(useUser.profile.email! || '');
      setReceiver('');
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  const confirmReceive = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/express/receive`, true).post({
      email: email,
      code: code,
      receiver: receiver,
      coin: coin,
    });

    if (result.code === 200) {
      window.history.pushState({}, '', '?check=' + result.data.id);
      dispatch(bottomDrawer(<Checker data={result.data} />));
      setCode('');
      setCode1('');
      setEmail(useUser.profile.email! || '');
      setReceiver('');
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  const coinOption = (fil?: string) => {
    const rows: any = [];

    const sortingArr = ['UUSD', 'USDT', 'cVoucher', 'TRX'];

    allData.coin
      .slice()
      .filter((e: any) => e.symbol !== fil)
      .sort((a: any, b: any) => sortingArr.indexOf(a.symbol) - sortingArr.indexOf(b.symbol))
      .forEach((v: any, k: number) => {
        rows.push(
          <div onClick={() => setCoin(v.symbol)} className={coin === v.symbol ? 'active' : ''} key={k}>
            <img crossOrigin='anonymous' src={`${config.apiUrl}/icons/${v?.symbol.toLowerCase()}.png`} />
            <div>
              <i>{v.nameFa}</i>
              <i>{v.symbol}</i>
            </div>
          </div>,
        );
      });

    return rows;
  };

  const companyOption = () => {
    const rows: any = [];

    Object.entries(companyTitle).forEach(([k, v]) => {
      rows.push(
        <span className={company === v ? 'inActiveTabs' : ''} onClick={() => setCompany(v)} key={k}>
          {v as any}
        </span>,
      );
    });

    return rows;
  };

  const tabOption = () => {
    const rows: any = [];

    Object.entries(tabTitle).forEach(([k, v]) => {
      rows.push(
        <span className={tab === v ? 'inActiveTabs' : ''} onClick={() => setTab(v)} key={k}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  useEffect(() => {
    const params = new URL(document.location.toString()).searchParams;
    const check = params.get('check');
    if (check) {
      dispatch(bottomDrawer(<Checker data={{ id: check }} />));
    }
  }, []);

  return (
    <>
      {/* <div className='tabsOver'>{companyOption()}</div> */}
      <div className='form'>
        {company === companyTitle.your ? (
          <div className='noItem'>
            تماس با ما در تلگرام:
            <br />
            <br />
            <a href='https://t.me/HotVoucher_Supp' target='_blank'>
              HotVoucher_Supp@
            </a>
          </div>
        ) : company !== companyTitle.one ? (
          <>
            <div className='inForm'>
              <div className='inputParentLarge'>{coinOption('cVoucher')}</div>
              <div className='inForm inFormLarge'>
                <div className='inputParent'>
                  <span>ایمیل شما</span>
                  <input
                    type='text'
                    placeholder='ایمیل شما'
                    className='rightPlace'
                    value={email}
                    disabled={useUser.profile.email ? true : false}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {current.voucher.use && (
                  <div className='inForm'>
                    <div className='inputParent'>
                      <span>کد ووچر</span>
                      <input type='text' placeholder='yyyyyyyyyy' className='rightPlace' value={code} onChange={(e) => setCode(e.target.value)} />
                    </div>
                    {(current.symbol === 'cVoucher' || current.symbol === 'PM') && (
                      <div className='inputParent'>
                        <span>شماره ووچر</span>
                        <input type='text' placeholder='xxxxxxxxxxxxxxxx' className='rightPlace' value={code1} onChange={(e) => setCode1(e.target.value)} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className='inForm inFormLeft'>
              {current && (
                <div className='ratePop'>
                  <div className='rate'>
                    نرخ دریافت {coin} از شما: <i>{utils.formatter(current.price.toman2 * 1.02)} ریال</i>
                  </div>
                  <div className='rate'>
                    نرخ دریافت {coin} در سایت {company}: <i>{utils.formatter(current.price.toman2)} ریال</i>
                  </div>
                  {/* <div className='rate'>اخرین بروز رسانی: دقایقی پیش</div> */}
                </div>
              )}
            </div>
            <div className='inForm inFormLeft'>
              <div className='infoDetail'>
                آخرین قیمت بروز رسانی از سایت های معتبر می باشد و بر پایه نرخ آزاد محاسبه می شود. در صورت بروز هرگونه مشکل تماس بگیرید.
              </div>
              <div className='mainButton' onClick={() => send()}>
                ثبت سفارش
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='tabs'>{tabOption()}</div>
            {tab === tabTitle.send ? (
              <>
                <div className='inForm'>
                  <div className='inputParentLarge'>{coinOption()}</div>
                  <div className='inForm inFormLarge'>
                    <div className='inputParent'>
                      <span>ایمیل شما</span>
                      <input
                        type='text'
                        placeholder='ایمیل شما'
                        className='rightPlace'
                        value={email}
                        disabled={useUser.profile.email ? true : false}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {current.voucher.use && (
                      <div className='inForm'>
                        <div className='inputParent'>
                          <span>کد ووچر</span>
                          <input type='text' placeholder='yyyyyyyyyy' className='rightPlace' value={code} onChange={(e) => setCode(e.target.value)} />
                        </div>
                        {(current.symbol === 'cVoucher' || current.symbol === 'PM') && (
                          <div className='inputParent'>
                            <span>شماره ووچر</span>
                            <input type='text' placeholder='xxxxxxxxxxxxxxxx' className='rightPlace' value={code1} onChange={(e) => setCode1(e.target.value)} />
                          </div>
                        )}
                      </div>
                    )}
                    <div className='inputParent'>
                      <span>ID دریافت کننده</span>
                      <input
                        type='text'
                        placeholder={`شماره کاربری ${company}`}
                        className='rightPlace'
                        value={receiver}
                        onChange={(e) => setReceiver(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className='inForm inFormLeft'>
                  {current && (
                    <div className='ratePop'>
                      <div className='rate'>
                        نرخ دریافت {coin} از شما: <i>{utils.formatter(current.price.toman2 * percent)} ریال</i>
                      </div>
                      <div className='rate'>
                        نرخ دریافت {coin} در سایت {company}: <i>{utils.formatter(current.price.toman2)} ریال</i>
                      </div>
                      {/* <div className='rate'>اخرین بروز رسانی: دقایقی پیش</div> */}
                    </div>
                  )}
                </div>
                <div className='inForm inFormLeft'>
                  <div className='infoDetail'>
                    آخرین قیمت بروز رسانی از سایت های معتبر می باشد و بر پایه نرخ آزاد محاسبه می شود. در صورت بروز هرگونه مشکل تماس بگیرید.
                  </div>
                  <div className='mainButton' onClick={() => send()}>
                    ثبت سفارش
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='inForm'>
                  <div className='inputParentLarge'>{coinOption()}</div>
                  <div className='inForm inFormLarge'>
                    <div className='inputParent'>
                      <span>ایمیل شما</span>
                      <input
                        type='text'
                        placeholder='ایمیل شما'
                        className='rightPlace'
                        value={email}
                        disabled={useUser.profile.email ? true : false}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className='inForm'>
                      <div className='inputParent'>
                        <span>کد تایید</span>
                        <input type='text' placeholder='xxxx' className='rightPlace' value={code} onChange={(e) => setCode(e.target.value)} />
                      </div>
                    </div>
                    <div className='inputParent'>
                      <span>ID دریافت کننده</span>
                      <input
                        type='text'
                        placeholder={`شماره کاربری ${company}`}
                        className='rightPlace'
                        value={receiver}
                        onChange={(e) => setReceiver(e.target.value)}
                      />
                    </div>
                  </div>
                  {(current.symbol === 'USDT' || current.symbol === 'TRX') && (
                    <div className='inputParent'>
                      <span>آدرس ولت دریافت کننده (TRC20)</span>
                      <input type='text' placeholder='T.............' className='rightPlace' value={wallet} onChange={(e) => setWallet(e.target.value)} />
                    </div>
                  )}
                </div>
                <div className='inForm inFormLeft'>
                  {current && (
                    <div className='ratePop'>
                      <div className='rate'>
                        نرخ ارسال {coin} به شما: <i>{utils.formatter(current.price.toman2)} ریال + ۱٪ بونوس</i>
                      </div>
                      <div className='rate'>
                        نرخ ارسال {coin} در سایت {company}: <i>{utils.formatter(current.price.toman2)} ریال</i>
                      </div>
                      {/* <div className='rate'>اخرین بروز رسانی: دقایقی پیش</div> */}
                    </div>
                  )}
                </div>
                <div className='inForm inFormLeft'>
                  <div className='infoDetail'>
                    آخرین قیمت بروز رسانی از سایت های معتبر می باشد و بر پایه نرخ آزاد محاسبه می شود. در صورت بروز هرگونه مشکل تماس بگیرید.
                  </div>
                  <div className='mainButton' onClick={() => receive()}>
                    ثبت سفارش
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Form;
